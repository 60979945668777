import { useParams, Navigate } from "react-router-dom";

function Sidebar() {
  return (
    <>
      <div className="sidebar p-3">
        <div className="mt-3">
          <div className="p-2 d-flex flex-column">
            <ul>
              <li>
                <a href="/">Mes projets</a>
              </li>
              <li>
                <a href="/taches">Mes Tâches</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-1">
          <div className="p-2 d-flex flex-column">
            <ul>
            <li>
                <a href="/tache-a-venir">Tâches future</a>
              </li>
              <li>
                <a href="/tache-en-cours">Tâches en cours</a>
              </li>
              <li>
                <a href="/tache-terminer">Tâches terminée</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-3">
          <div className="p-2 d-flex flex-column">
            <ul>
              <li>
                <a href="/configuration-tache">Config tâches</a>
              </li>
              <li>
                <a href="/configuration-thematique">Config thématique</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-3">
          <div className="p-2 d-flex flex-column">
            <ul>
              <li>
                <a href="/api-endpoints">API</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
