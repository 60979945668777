import React from "react";
import Header from '../Layout/Header';
import Sidebar from '../Layout/Sidebar';
import apiEndpoints from "../../constant/constant_api_endpoints";
import "bootstrap/dist/css/bootstrap.min.css";

const ApiExplorer = () => {
  return (
    <>
      <Header />
      <div className="d-flex flex-row">
        <Sidebar />
        <div className="container-fluid p-3 mt-3">
          <h1 className="titre-page">API Explorer</h1>
          <div className="accordion" id="apiAccordion">
            {apiEndpoints.map((endpoint, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`heading-${index}`}>
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse-${index}`}
                    aria-expanded="false"
                    aria-controls={`collapse-${index}`}
                  >
                    {endpoint.name}
                  </button>
                </h2>
                <div
                  id={`collapse-${index}`}
                  className="accordion-collapse collapse"
                  aria-labelledby={`heading-${index}`}
                  data-bs-parent="#apiAccordion"
                >
                  <div className="accordion-body">
                    <p><strong>Méthode :</strong> {endpoint.method}</p>
                    <p><strong>URL :</strong> {endpoint.url}</p>
                    <p><strong>Paramètres :</strong></p>
                    {endpoint.params.length > 0 ? (
                      <ul>
                        {endpoint.params.map((param, idx) => (
                          <li key={idx}>
                            {param.name} ({param.type}){" "}
                            {param.required && <strong>(requis)</strong>}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <p>Aucun paramètre requis.</p>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApiExplorer;
