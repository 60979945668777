// apiEndpoints.js
const apiEndpoints = [
    // Endpoints pour Projet
    {
      name: "Nouveau Projet",
      method: "POST",
      url: "/api/nouveau-projet",
      params: [
        { name: "nom_projet", type: "string", required: true },
        { name: "nom_thematique", type: "string", required: false },
        { name: "nom_entreprise", type: "string", required: false },
        { name: "domaine", type: "string", required: false },
        { name: "site_internet", type: "string", required: false },
        { name: "cid", type: "string", required: false },
        { name: "url_fiche", type: "string", required: false },
        { name: "adresse", type: "string", required: false },
        { name: "telephone", type: "string", required: false },
        { name: "jour_traitement", type: "array", required: false },
        { name: "tache_automatique", type: "boolean", required: false },
      ],
    },
    {
      name: "Liste des Projets",
      method: "GET",
      url: "/api/liste-projet",
      params: [],
    },
    {
      name: "Liste des Projets par Tâche",
      method: "GET",
      url: "/api/liste-projet-par-tache",
      params: [],
    },
    {
      name: "Obtenir un Projet par ID",
      method: "GET",
      url: "/api/get-projet/{id}",
      params: [
        { name: "id", type: "integer", required: true },
      ],
    },
    {
      name: "Supprimer Projet",
      method: "POST",
      url: "/api/supprimer-projet",
      params: [
        { name: "id_projet", type: "integer", required: true },
      ],
    },
  
    // Endpoints pour Tâche
    {
      name: "Nouvelle Tâche",
      method: "POST",
      url: "/api/nouveau-tache",
      params: [
        { name: "nom_tache", type: "string", required: true },
        { name: "description", type: "string", required: true },
        // Ajoutez d'autres champs requis ici
      ],
    },
    {
      name: "Liste des Tâches",
      method: "GET",
      url: "/api/liste-tache",
      params: [],
    },
    {
      name: "Obtenir une Tâche par ID",
      method: "GET",
      url: "/api/get-tache/{id}",
      params: [
        { name: "id", type: "integer", required: true },
      ],
    },
    {
      name: "Tâches à Traiter",
      method: "GET",
      url: "/api/tache/a/traiter",
      params: [],
    },
    {
      name: "Tâches en Cours",
      method: "GET",
      url: "/api/tache/en/cours",
      params: [],
    },
    {
      name: "Tâches à Venir",
      method: "GET",
      url: "/api/tache/a/venir",
      params: [],
    },
    {
      name: "Tâches Terminées",
      method: "GET",
      url: "/api/tache/terminer",
      params: [],
    },
    {
      name: "Retour Zenno",
      method: "GET",
      url: "/api/tache/retour/zenno",
      params: [
        { name: "idtask", type: "integer", required: true },
        { name: "statut", type: "string", required: true },
      ],
    },
    {
      name: "Tâches en Retard",
      method: "GET",
      url: "/api/tache/en/retard",
      params: [],
    },
    {
      name: "Supprimer Tâche",
      method: "POST",
      url: "/api/supprimer-tache",
      params: [
        { name: "id_tache", type: "integer", required: true },
      ],
    },
    {
      name: "Statistiques des Tâches",
      method: "GET",
      url: "/api/statistique-tache",
      params: [],
    },
    {
      name: "Tâches par Projet",
      method: "GET",
      url: "/api/taches-par-projet/{nomProjet}",
      params: [
        { name: "nomProjet", type: "string", required: true },
      ],
    },
    {
      name: "Tâches par Type et Projet",
      method: "GET",
      url: "/api/get-taches-par-type-et-projet/{nomProjet}",
      params: [
        { name: "nomProjet", type: "string", required: true },
      ],
    },
  
    // Configurations des Tâches
    {
      name: "Nouvelle Configuration de Tâche",
      method: "POST",
      url: "/api/nouveau-config-tache",
      params: [
        { name: "nom_type_tache", type: "string", required: true },
      ],
    },
    {
      name: "Liste des Configurations de Tâches",
      method: "GET",
      url: "/api/liste-config-tache",
      params: [],
    },
    {
      name: "Obtenir une Configuration de Tâche",
      method: "GET",
      url: "/api/get-config-tache/{id}",
      params: [
        { name: "id", type: "integer", required: true },
      ],
    },
    {
      name: "Supprimer Configuration de Tâche",
      method: "POST",
      url: "/api/supprimer-config-tache",
      params: [
        { name: "id_config_tache", type: "integer", required: true },
      ],
    },
  
    // Configurations des Thématiques
    {
      name: "Nouvelle Configuration de Thématique",
      method: "POST",
      url: "/api/nouveau-config-thematique",
      params: [
        { name: "nom_thematique", type: "string", required: true },
      ],
    },
    {
      name: "Liste des Configurations de Thématiques",
      method: "GET",
      url: "/api/liste-config-thematique",
      params: [],
    },
    {
      name: "Obtenir une Configuration de Thématique",
      method: "GET",
      url: "/api/get-config-thematique/{id}",
      params: [
        { name: "id", type: "integer", required: true },
      ],
    },
    {
      name: "Supprimer Configuration de Thématique",
      method: "POST",
      url: "/api/supprimer-config-thematique",
      params: [
        { name: "id_thematique", type: "integer", required: true },
      ],
    },
  ];
  
  export default apiEndpoints;
  